const isProduction = process.env.NODE_ENV === 'production'; // Check if we are in production

// Define URLs for development and production
const config = {
  DEV_URL: 'http://localhost:8000',
  PROD_URL: 'http://95.216.143.255',
};

// Export the appropriate URL based on the environment
export const CURRENT_URL = isProduction ? config.PROD_URL : config.DEV_URL;
