<template>
  <v-container class="d-flex justify-center align-center app-container">
    <v-row class="d-flex justify-center align-center" style="width: 100%;">
      <v-col cols="12" sm="8" md="6">
        <h2 class="header text-center">Asiakkaat</h2>
        <v-expansion-panels>
          <v-expansion-panel v-for="customer in customers" :key="customer.id" class="customer-panel">
            <v-expansion-panel-title>
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="4">
                  <strong>{{ customer.name }}</strong>
                </v-col>
                <v-col class="text--secondary" cols="8">
                  <v-fade-transition leave-absolute></v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-text-field v-model="customer.name" label="Nimi" outlined dense></v-text-field>
              <v-text-field v-model="customer.address" label="Osoite" outlined dense></v-text-field>
              <v-text-field v-model="customer.vat_number" label="Alv-tunnus" outlined dense></v-text-field>
              <v-btn class="save-btn" variant="tonal" @click="editCustomer(customer)">
                Tallenna
              </v-btn>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn class="customer-create" @click="dialog = true" variant="tonal">Luo uusi asiakas</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="400" transition="dialog-transition">
      <customer-create-card @close-card="dialog = false"></customer-create-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000" color="success" top>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarerror" timeout="3000" color="error" top>
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarerror = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import CustomerCreateCard from '../components/CustomerCreateCard.vue';
import { CURRENT_URL } from '@/config';

export default {
  components: { CustomerCreateCard },
  data() {
    return {
      customers: [],
      snackbar: false,
      snackbarerror: false,
      snackbarMessage: '',
      errorMessage: '',
      dialog: false,
    };
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    async fetchCustomers() {
      try {
        const response = await axios.get(`${CURRENT_URL}/api/customers/`);
        this.customers = response.data;
        console.log('Customers:', this.customers);
      } catch (error) {
        this.errorMessage = 'Virhe asiakkaiden haussa';
        this.snackbarerror = true;
        console.error('Error fetching customers:', error);
      }
    },
    async editCustomer(customer) {
      try {
        const response = await axios.put(`${CURRENT_URL}/api/customers/${customer.id}/`, customer);
        console.log('Customer updated:', response.data);

        this.snackbarMessage = 'Asiakkaan muokkaus onnistui!';
        this.snackbar = true;

        setTimeout(() => window.location.reload(), 1000);
      } catch (error) {
        console.error('Error updating customer:', error.response ? error.response.data : error.message);
        this.errorMessage = 'Virhe asiakkaan muokkaamisessa. Tarkista tiedot.';
        this.snackbarerror = true;
      }
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
  min-width: 100vw;
}

.header {
  padding: 15px 0;
  font-weight: 600;
  font-size: 1.5rem;
}

.customer-panel {
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.v-expansion-panels {
  width: 100%;
}

.customer-create {
  margin-top: 20px;
  background-color: #31aee2; /* Logo color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
}

.save-btn {
  margin-top: 10px;
  background-color: #31aee2; /* Logo color */
  color: white;
  border-radius: 8px;
  text-transform: none;
}

.v-btn {
  transition: background-color 0.3s ease;
}

.v-btn:hover {
  background-color: #1e8db5; /* Darker shade for hover effect */
}

.v-dialog {
  border-radius: 8px;
}

.v-snackbar {
  border-radius: 8px;
}
</style>
