import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import TaskCreatePage from '@/views/TaskCreatePage.vue';
import CustomersPage from '@/views/CustomersPage.vue';
import TasksPage from '@/views/TasksPage.vue';

const routes = [
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/task_creation',
    name: 'TaskCreatePage',
    component: TaskCreatePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/customers_list',
    name: 'CustomersPage',
    component: CustomersPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks_list',
    name: 'TasksPage',
    component: TasksPage,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guard to check authentication
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('authToken'); // Or use your own authentication check

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        // If the route requires authentication and the user is not logged in
        next('/'); // Redirect to login page
    } else {
        next(); // Proceed to the route
    }
});

export default router;