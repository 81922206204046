<template>
  <v-app>
    <v-container class="d-flex flex-column justify-center align-center app-container">
      <v-col cols="auto" class="d-flex justify-center mb-4">
        <v-img
          src="@/assets/TaskDriftTrans.png"
          alt="Company Logo"
          width="200"
          class="logo-img"
        ></v-img>
      </v-col>
      <v-col cols="auto" class="d-flex justify-center">
        <v-card class="pa-5 login-card" width="100%">
          <v-card-title class="headline text-center">Kirjaudu</v-card-title>
          <v-form v-model="valid" ref="form">
            <v-text-field
              v-model="username"
              label="Username"
              :rules="[v => !!v || 'Username is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              :rules="[v => !!v || 'Password is required']"
              type="password"
              required
            ></v-text-field>
            <v-btn class="main-btn" @click="login()" :disabled="!valid">Kirjaudu</v-btn>
          </v-form>
          <v-alert v-if="errorMessage" type="error" dismissible>
            {{ errorMessage }}
          </v-alert>
        </v-card>
      </v-col>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import { CURRENT_URL } from '../config';

export default {
  data() {
    return {
      username: '',
      password: '',
      valid: false,
      errorMessage: '',
    };
  },
  created() {
    this.autologin();
  },
  methods: {
    async autologin() {
      const isAuthenticated = localStorage.getItem('authToken');
      if (isAuthenticated) {
        this.$router.push('/home');
      }
    },
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Check if the cookie string begins with the name we want
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },
    async login() {
      try {
        const csrftoken = this.getCookie('csrftoken');

        const response = await axios.post(`${CURRENT_URL}/api/login/`, {
          username: this.username,
          password: this.password,
        }, {
          headers: {
            'X-CSRFToken': csrftoken
          }
        });
        if (response.data.success) {
          localStorage.setItem('authToken', response.data.token); // Store the token in localStorage
          this.$router.push('/home'); // Redirect to the Home page
          this.errorMessage = ''; // Clear any error messages
        } else {
          this.errorMessage = 'Väärä käyttäjänimi tai salasana';
        }
      } catch (error) {
        this.errorMessage = 'Sisäänkirjautuminen epäonnistui. Ota yhteys tukeen.';
      }
    },
  },
};
</script>

<style scoped>
.app-container {
  background: rgb(248, 248, 248); /* Light background for contrast */
  min-height: 100vh;
  min-width: 100vw;
}

.login-card {
  max-width: 600px; /* Set maximum width for the card */
  width: 95%; /* Responsive width */
  border-radius: 12px; /* Rounded corners for the card */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.main-btn {
  background-color: #31aee2; /* Branding color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 100%; /* Full width */
  height: 50px; /* Set height for larger button size */
  font-size: 1.1rem; /* Increased font size */
  padding: 10px 0; /* Increased padding for larger button size */
}

.logo-img {
  max-width: 300px; /* Limits logo width */
  max-height: 300px; /* Limits logo height */
  width: 100%; /* Ensures it remains responsive */
  height: auto; /* Maintains aspect ratio */
}

.main-btn:hover {
  background-color: #1e8db5; /* Darker shade for hover effect */
}
.v-col{
  width: 80%;
}
</style>
