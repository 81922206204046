<template>
  <v-container class="d-flex align-center justify-center app-container" style="height: 100vh;">
    <v-row class="d-flex justify-center align-center" style="width: 100%;">
      <v-col cols="12" sm="10" md="8" lg="6">
        <h2 class="header text-center">Työn kirjaus</h2>
        <v-date-input v-model="task.date" label="Valitse päivä" class="mb-4"></v-date-input>
        <v-select 
          :items="customers" 
          density="comfortable" 
          label="Asiakas" 
          v-model="task.customer"
          :item-props="itemProps"
          class="mb-4"
        ></v-select>
        <v-text-field v-model="task.name" label="Nimi" class="mb-4"></v-text-field>
        <v-text-field v-model="task.type" label="Tyyppi" class="mb-4"></v-text-field>
        <v-text-field v-model="task.address" label="Osoite" class="mb-4"></v-text-field>
        <v-text-field v-model="task.description" label="Selite" class="mb-4"></v-text-field>
        <v-text-field v-model="task.task_duration" label="Kulunut aika" class="mb-4"></v-text-field>
        <v-checkbox v-model="postOnSave" class="mb-4">
          <template v-slot:label>
            <div>Lähetä laskutukseen tallentaessa</div>
          </template>
        </v-checkbox>
        <v-btn class="main-btn" variant="tonal" @click="createTask()">Tallenna työ</v-btn>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" timeout="3000" color="success">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarerror" timeout="3000" color="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarerror = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { VDateInput } from 'vuetify/labs/VDateInput'
import axios from 'axios';
import { CURRENT_URL } from '@/config';

export default {
  data() {
    return {
      customers: [],
      task: {
        name: '',
        customer: null,
        type: '',
        address: '',
        description: '',
        date: null,
        task_duration: '',
      },
      postOnSave: false,
      snackbar: false,
      snackbarerror: false,
      snackbarMessage: '',
      errorMessage: '',
    };
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    async createTask() {
      try {
        const dateString = this.task.date;
        const date = new Date(dateString);

        // Extract year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        // Format to YYYY-MM-DD
        const formattedDate = `${year}-${month}-${day}`;
        this.task.date = formattedDate;

        const response = await axios.post(`${CURRENT_URL}/api/task-create/`, this.task);
        console.log(response);

        console.log('Task created:', response.data);
        // Show success notification
        this.snackbarMessage = 'Kirjaus luoto onnistuneesti!';
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push('/home');
        }, 3000);
      } catch (error) {
        if (error.response) {
          console.error('Error creating task:', error.response.data);
          this.errorMessage = 'Virhe kirjauksen luonnissa. Tarkista, että kentät on täytetty.';
          this.snackbarerror = true;
        } else {
          console.error('Network or server error:', error.message);
          this.errorMessage = 'Verkkovirhe. Ota yhteys tukeen.';
        }
      }
    },
    async fetchCustomers() {
      try {
        const response = await axios.get(`${CURRENT_URL}/api/customers/`);
        this.customers = response.data;
        console.log('Customers:', this.customers);
      } catch (error) {
        this.errorMessage = 'Virhe asiakkaiden haussa';
      }
    },
    itemProps(item) {
      return {
        title: item.name,
        value: item.id,
      }
    },
  },
  components: {
    VDateInput,
  },
}
</script>

<style scoped>
.header {
  padding: 15px;
}

.app-container {
  background-color: #f8f8f8; /* Light background for contrast */
  min-height: 100vh;
  min-width: 100vw;
}

.main-btn {
  background-color: #31aee2; /* Your branding color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 100%; /* Full width */
  height: 50px; /* Button height */
  font-size: 1.1rem; /* Larger font size */
  padding: 10px 0; /* Vertical padding for larger button size */
}

.main-btn:hover {
  background-color: #1e8db5; /* Darker shade for hover effect */
}
</style>
