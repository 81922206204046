<template>
  <v-container class="d-flex flex-column justify-center align-center app-container">
    <v-col cols="auto" class="d-flex justify-center mb-4">
      <v-img
        src="@/assets/TaskDriftTrans.png"
        alt="Company Logo"
        class="logo-img"
      ></v-img>
    </v-col>
    <v-row justify="center" class="button-row">
      <v-col>
        <v-btn prepend-icon="mdi-note-plus" class="main-btn" @click="$router.push('/task_creation');">
          Kirjaa työ
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="button-row">
      <v-col>
        <v-btn prepend-icon="mdi-clipboard-list" class="main-btn" @click="$router.push('/tasks_list');">
          Kirjatut työt
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="button-row">
      <v-col>
        <v-btn prepend-icon="mdi-account-multiple" class="main-btn" @click="$router.push('/customers_list');">
          Asiakkaat
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="button-row">
      <v-col>
        <v-btn class="logout-btn" @click="logout">
          Kirjaudu ulos
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',

  methods: {
    logout() {
      localStorage.removeItem('authToken'); // Remove the token
      this.$router.push('/'); // Redirect to the login page
    },
  },
};
</script>

<style scoped>
.app-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f9f9f9; /* Light background for contrast */
}

.v-row {
  margin-bottom: 20px; /* Space between rows */
}

.button-row {
  max-height: 10%;
}

.logo-img {
  max-width: 300px; /* Limits logo width */
  max-height: 300px; /* Limits logo height */
  width: 100%; /* Ensures it remains responsive */
  height: auto; /* Maintains aspect ratio */
}

.main-btn {
  background-color: #31aee2; /* Logo color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 220px; /* Increased fixed width for larger buttons */
  height: 50px; /* Set height for larger button size */
  font-size: 1.1rem; /* Increased font size */
  padding: 10px 20px; /* Increased padding for larger button size */
}

.main-btn:hover {
  background-color: #1e8db5; /* Darker shade for hover effect */
}

.logout-btn {
  background-color: #ff3d00; /* Logout button color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 220px; /* Increased fixed width for larger button */
  height: 50px; /* Set height for larger button size */
  font-size: 1.1rem; /* Increased font size */
  padding: 10px 20px; /* Increased padding for larger button size */
}

.logout-btn:hover {
  background-color: #c62828; /* Darker shade for hover effect */
}
.v-col{
  width: 75%;
}
</style>
