<template>
    <v-card>
        <v-card-title class="text-h5">Luo asiakas</v-card-title>

        <v-card-text>
            <v-form>
                <!-- Text Input Fields -->
                <v-text-field label="Nimi" v-model="customer.name"></v-text-field>
                <v-text-field label="Osoite" v-model="customer.address"></v-text-field>
                <v-text-field label="Alv-tunnus" v-model="customer.vat_number"></v-text-field>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeCard">
                Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="createCustomer">
                Submit
            </v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="3000" color="success">
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarerror" timeout="3000" color="error">
        {{ errorMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbarerror = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { CURRENT_URL } from '@/config';
import axios from 'axios';
export default {
    name: 'FormCard',
    data() {
        return {
            customer: {
                name: '',
                address: '',
                vat_number: ''
            },
            snackbar: false,
            snackbarerror: false,
            snackbarMessage: '',
            errorMessage: '',
        };
    },
    methods: {
        async createCustomer() {
            try {
                const response = await axios.post(`${CURRENT_URL}/api/customer-create/`, this.customer);
                console.log(response);
                console.log('Customer created:', response.data);
                // Show success notification
                this.snackbarMessage = 'Asiakas luoto onnistuneesti!';
                this.snackbar = true;
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (error) {
                if (error.response) {
                    console.error('Error creating task:', error.response.data);
                    this.errorMessage = 'Virhe asiakkaan luonnissa. Tarkista, että kentät on täytetty.';
                    this.snackbarerror = true;
                } else {
                    console.error('Network or server error:', error.message);
                    this.errorMessage = 'Verkkovirhe. Ota yhteys tukeen.';
                }
            }

        },
        closeCard() {
            this.$emit('close-card'); // Emit an event to the parent to close the dialog
        }
    }
};
</script>

<style scoped>
/* Optional styling */
</style>