<template>
  <v-container class="d-flex justify-center align-center app-container">
    <v-row class="d-flex justify-center align-center" style="width: 100%;">
      <v-col cols="12" sm="8" md="6">
        <h2 class="header text-center">Kirjatut työt</h2>
        <v-expansion-panels>
          <v-expansion-panel v-for="customer in customers" :key="customer.id">
            <v-expansion-panel-title>
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="4">
                  {{ customer.name }}
                </v-col>
                <v-col class="text--secondary" cols="8">
                  <v-fade-transition leave-absolute></v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row v-for="(task, index) in filteredTasks(customer.id)" :key="task.id" align="center" class="task-row">
                <v-col cols="auto">
                  <v-checkbox 
                    :key="index" 
                    :value="task" 
                    :input-value="isTaskSelected(task)"
                    @change="toggleTaskSelection(task)"
                    class="task-checkbox"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        <v-row no-gutters>
                          <v-col class="d-flex justify-start" cols="4">
                            {{ task.name }}
                          </v-col>
                          <v-col class="text--secondary" cols="8">
                            <v-fade-transition leave-absolute></v-fade-transition>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-date-input v-model="task.date" label="Valitse päivä"></v-date-input>
                        <v-select 
                          :items="customers" 
                          density="comfortable" 
                          label="Asiakas" 
                          v-model="task.customer"
                          :item-props="itemProps">
                        </v-select>
                        <v-text-field v-model="task.name" label="Nimi"></v-text-field>
                        <v-text-field v-model="task.type" label="Tyyppi"></v-text-field>
                        <v-text-field v-model="task.address" label="Osoite"></v-text-field>
                        <v-text-field v-model="task.description" label="Selite"></v-text-field>
                        <v-text-field v-model="task.task_duration" label="Kulunut aika"></v-text-field>
                        <v-btn @click="editTask(task)" class="main-btn">Tallenna</v-btn>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row> 
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn @click="sendTasks" class="send-tasks-btn">Send tasks</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="3000" color="success">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarerror" timeout="3000" color="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarerror = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { CURRENT_URL } from '@/config';
import axios from 'axios';

export default {
  data() {
    return {
      customers: [],
      tasks: [],
      snackbar: false,
      snackbarerror: false,
      snackbarMessage: '',
      errorMessage: '',
      dialog: false,
      postOnSave: false,
      tasksToSend: [],
    };
  },
  created() {
    this.fetchCustomers();
    this.fetchTasks();
  },
  methods: {
    toggleTaskSelection(task) {
      const index = this.tasksToSend.findIndex(t => t.id === task.id);
      if (index === -1) {
        this.tasksToSend.push(task); // Add task if it is not in the list
      } else {
        this.tasksToSend.splice(index, 1); // Remove task if it is in the list
      }
    },

    sendTasks() {
      console.log(this.tasksToSend);
      // Implement your sending logic here
    },

    isTaskSelected(task) {
      return this.tasksToSend.some(t => t.id === task.id);
    },

    filteredTasks(customerId) {
      return this.tasks.filter(task => task.customer === customerId);
    },

    async editTask(task) {
      try {
        const response = await axios.put(`${CURRENT_URL}/api/tasks/${task.id}/`, task);
        console.log('Task updated:', response.data);
        this.snackbarMessage = 'Kirjauksen muokkaus onnistui!';
        this.snackbar = true;

        // Reload the tasks after updating
        await this.fetchTasks();
      } catch (error) {
        console.error('Error updating task:', error.response ? error.response.data : error.message);
        this.errorMessage = 'Virhe kirjauksen muokkaamisessa. Tarkista tiedot.';
        this.snackbarerror = true;
      }
    },

    async fetchCustomers() {
      try {
        const response = await axios.get(`${CURRENT_URL}/api/customers/`);
        this.customers = response.data;
        console.log('Customers:', this.customers);
      } catch (error) {
        this.errorMessage = 'Virhe asiakkaiden haussa';
        this.snackbarerror = true;
      }
    },

    async fetchTasks() {
      try {
        const response = await axios.get(`${CURRENT_URL}/api/tasks/`);
        this.tasks = response.data;
        console.log('Tasks:', this.tasks);
      } catch (error) {
        this.errorMessage = 'Virhe kirjausten haussa';
        this.snackbarerror = true;
      }
    },

    itemProps(item) {
      return {
        title: item.name,
        value: item.id,
      }
    },
  },
}
</script>

<style scoped>
.header {
  padding: 15px;
  font-size: 2rem; /* Adjust header font size */
  font-weight: bold; /* Make the header bold */
  color: #333; /* Change header color */
  margin-bottom: 20px; /* Add space below the header */
}

.task-row {
  padding: 10px 0; /* Add spacing between tasks */
  border-bottom: 1px solid #ddd; /* Add a border between tasks */
  display: flex; /* Use flex to align items */
  align-items: center; /* Center items vertically */
}

.main-btn {
  background-color: #31aee2; /* Your branding color */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 100%; /* Full width */
  height: 50px; /* Button height */
  font-size: 1.1rem; /* Larger font size */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.main-btn:hover {
  background-color: #1e8db5; /* Darker shade for hover effect */
}

.send-tasks-btn {
  background-color: #28a745; /* Green for send tasks */
  color: white;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  width: 100%; /* Full width */
  height: 50px; /* Button height */
  font-size: 1.1rem; /* Larger font size */
  margin-top: 15px; /* Spacing above the button */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.send-tasks-btn:hover {
  background-color: #218838; /* Darker green for hover effect */
}

.task-checkbox {
  margin-right: 15px; /* Space between checkbox and task name */
  padding-top: 10px;
}

/* Add some padding and background color to expansion panels */
.v-expansion-panel {
  margin-bottom: 15px; /* Space between panels */
  border-radius: 8px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
}

/* Adjust text color for better visibility */
.text--secondary {
  color: #666; /* Secondary text color */
}

/* Style for v-date-input and v-text-field */
.v-text-field,
.v-date-input,
.v-select {
  margin-bottom: 15px; /* Spacing below inputs */
}

.app-container {
  background-color: #f8f8f8; /* Light background for contrast */
  min-height: 100vh;
  min-width: 100vw;
}

</style>