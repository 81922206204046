<template>
  <v-app>
    <router-view></router-view> <!-- This is where the page content will be rendered -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
/* Add any global styling here if needed */
</style>